import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Switch, Route } from "react-router-dom";
import Topbar from "./global/layoutComponents/Topbar";
import Sidebar2 from "./global/layoutComponents/Sidebar";
import { useEffect, useState } from "react";
import { SidebarContextProvider } from "./global/layoutComponents/SidebarContext";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SliceActions } from "./global/redux/auth0";
import { useDispatch } from "react-redux";

import RondologDataVerification from "./scenes/RondologDataVerification";
import UploadPage from "./scenes/Upload";
import CheckoutRomaneioCte from "./scenes/CheckoutRomaneioCte";
import Entrypoint from "./scenes/entrypoint";
import Trades from "./scenes/Trades";
import GestorCheckoutResolution from "./scenes/GestorCheckoutResolution";
import GestorBaixaResolution from "./scenes/GestorBaixaResolution";
import RondologPaymentVerify from "./scenes/RondologPaymentVerify";
import GestorDocApproval from "./scenes/GestorDocApproval";
import GestorLiquidacao from "./scenes/GestorLiquidacao";
import XamaAntecipacaoViz from "./scenes/XamaAntecipacaoViz";
import XamaGraphStatistics from "./scenes/XamaGraphStatistics";
import XamaListaEntregadores from "./scenes/XamaListaEntregadores";
import XamaPersonStatus from "./scenes/XamaPersonStatus";
import XamaCheckout from "./scenes/XamaCheckout";
import XamaPaymentVerify from "./scenes/XamaPaymentVerify";
import XptoAntecipacaoViz from "./scenes/XptoAntecipacaoViz";
import XptoPersonStatus from "./scenes/XptoPersonStatus";
import XptoCheckout from "./scenes/XptoCheckout";
import XptoPaymentVerify from "./scenes/XptoPaymentVerify";
import Faq from "./scenes/faq";

function App() {
  const [theme, colorMode] = useMode();
  const [accessToken, setAcessToken] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const screens = process.env.REACT_APP_SCREENS.split(" ");
  const sidebarName = process.env.REACT_APP_NAME_SIDEBAR;
  const dispatch = useDispatch();

  if (!isAuthenticated) {
    console.log("Não autenticado");
    logout();
  }

  useEffect(() => {
    getAccessTokenSilently().then((r) => {
      dispatch(auth0SliceActions.updateAccessToken({ accessToken: r }));
    });
  }, [isAuthenticated]);

  dispatch(auth0SliceActions.updateUser({ user: user }));

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SidebarContextProvider>
          <CssBaseline />
          <div className="app">
            <Sidebar2 name={sidebarName} />
            <main className="content">
              <Topbar />
              <Routes>
                <Route path="/" element={<Faq />} />
                {screens.includes("rondologRomaneio") && (
                  <Route
                    path="/romaneio"
                    element={
                      <UploadPage
                        key="romaneio"
                        title="Upload Romaneio"
                        subtitle="Arquivos com informações referentes ao Romaneio"
                        docType="rondolog_romaneio"
                        extensionFile=".xlsx"
                        uploadTitle="Upload de Romaneio"
                      />
                    }
                  />
                )}
                {screens.includes("rondologAgregado") && (
                  <Route
                    path="/cadastroagregado"
                    element={
                      <UploadPage
                        key="cadastroagregado"
                        title="Cadastro de Agregados"
                        subtitle="Arquivos com informações referentes ao cadastro de Agregados"
                        docType="rondolog_agregado"
                        extensionFile=".xls"
                        uploadTitle="Upload de Agregados"
                      />
                    }
                  />
                )}

                {screens.includes("cteRondolog") && (
                  <Route
                    path="/cte"
                    element={
                      <UploadPage
                        key="cte"
                        title="Cadastro CT-e"
                        subtitle="Arquivos XML das CT-e"
                        docType="cte_xml_rondolog"
                        extensionFile=".xml"
                        uploadTitle="Upload de CT-e"
                      />
                    }
                  />
                )}

                {screens.includes("ifoodFinanceiroUpload") && (
                  <Route
                    path="/ifoodFinanceiro"
                    element={
                      <UploadPage
                        key="ifoodFinanceiro"
                        title="Ifood Financeiro"
                        subtitle="Arquivo Financeiro do Ifood"
                        docType="ifood_financeiro"
                        extensionFile=".csv"
                        uploadTitle="Upload Ifood Financeiro"
                      />
                    }
                  />
                )}

                {screens.includes("ifoodPerformanceUpload") && (
                  <Route
                    path="/ifoodPerformance"
                    element={
                      <UploadPage
                        key="ifoodPerformance"
                        title="Ifood Performance"
                        subtitle="Arquivo Performance do Ifood"
                        docType="ifood_performance"
                        extensionFile=".csv"
                        uploadTitle="Upload Ifood Performance"
                      />
                    }
                  />
                )}

                {screens.includes("ifoodCadastroUpload") && (
                  <Route
                    path="/ifoodCadastro"
                    element={
                      <UploadPage
                        key="ifoodCadastro"
                        title="Ifood Cadastro"
                        subtitle="Arquivo Cadastro dos Moto-Boys para Ifood"
                        docType="ifood_cadastro"
                        extensionFile=".csv"
                        uploadTitle="Upload Ifood Cadastro"
                      />
                    }
                  />
                )}

                {screens.includes("muveFinanceiro") && (
                  <Route
                    path="/muveFinanceiro"
                    element={
                      <UploadPage
                        key="muveFinanceiro"
                        title="Muve Financeiro"
                        subtitle="Arquivo entregador por período Muve"
                        docType="muve_financeiro"
                        extensionFile=".xls"
                        uploadTitle="Upload Muve Financeiro"
                      />
                    }
                  />
                )}

                {screens.includes("muveFinanceiroGarantido") && (
                  <Route
                    path="/muveFinanceiroGarantido"
                    element={
                      <UploadPage
                        key="muveFinanceiroGarantido"
                        title="Muve Garantido"
                        subtitle="Arquivo entregador por período Garantido Muve"
                        docType="muve_financeiro_garantido"
                        extensionFile=".xlsx"
                        uploadTitle="Upload Muve Financeiro Garantido"
                      />
                    }
                  />
                )}

                {screens.includes("muvePedidos") && (
                  <Route
                    path="/muvePedidos"
                    element={
                      <UploadPage
                        key="muvePedidos"
                        title="Muve Pedidos"
                        subtitle="Arquivo Pedidos Muve"
                        docType="muve_pedidos"
                        extensionFile=".xls"
                        uploadTitle="Upload Muve Pedidos"
                      />
                    }
                  />
                )}

                {screens.includes("xptoFinanceiroGarantido") && (
                  <Route
                    path="/xptoFinanceiroGarantido"
                    element={
                      <UploadPage
                        key="xptoFinanceiroGarantido"
                        title="Xpto Garantido"
                        subtitle="Arquivo entregador por período Garantido XPTO"
                        docType="xpto_financeiro_garantido"
                        extensionFile=".xlsx"
                        uploadTitle="Upload XPTO Financeiro Garantido"
                      />
                    }
                  />
                )}

                {screens.includes("xptoPedidos") && (
                  <Route
                    path="/xptoPedidos"
                    element={
                      <UploadPage
                        key="xptoPedidos"
                        title="Xpto Pedidos"
                        subtitle="Arquivo Pedidos XPTO"
                        docType="xpto_pedidos"
                        extensionFile=".xls"
                        uploadTitle="Upload XPTO Pedidos"
                      />
                    }
                  />
                )}

                {screens.includes("rondologDataVerification") && (
                  <Route
                    path="/verificacaodados"
                    element={<RondologDataVerification />}
                  />
                )}

                {screens.includes("gestorCheckout") && (
                  <Route
                    path="/checkoutResolution"
                    element={<GestorCheckoutResolution />}
                  />
                )}

                {screens.includes("gestorBaixa") && (
                  <Route
                    path="/gestorBaixa"
                    element={<GestorBaixaResolution />}
                  />
                )}

                {screens.includes("gestorDocApproval") && (
                  <Route
                    path="/gestorDocApproval"
                    element={<GestorDocApproval />}
                  />
                )}

                {screens.includes("gestorLiquidacao") && (
                  <Route
                    path="/gestorLiquidacao"
                    element={<GestorLiquidacao />}
                  />
                )}

                {screens.includes("rondologPaymentVerify") && (
                  <Route
                    path="/rondologPayments"
                    element={<RondologPaymentVerify />}
                  />
                )}

                {screens.includes("checkoutRomaneioCte") && (
                  <Route path="/checkout" element={<CheckoutRomaneioCte />} />
                )}

                {screens.includes("xamaPaymentVerify") && (
                  <Route path="/xamaPayments" element={<XamaPaymentVerify />} />
                )}

                {screens.includes("xamaAntecipacaoViz") && (
                  <Route
                    path="/antecipacaoViz"
                    element={<XamaAntecipacaoViz />}
                  />
                )}

                {screens.includes("xamaGraphStatistics") && (
                  <Route
                    path="/xamaGraphStatistics"
                    element={<XamaGraphStatistics />}
                  />
                )}

								{screens.includes("xamaListaEntregadores") && (
									<Route path="/listaEntregadores" element={<XamaListaEntregadores />} />
								)}

                {screens.includes("xamaPersonStatus") && (
                  <Route path="/personStatus" element={<XamaPersonStatus />} />
                )}

                {screens.includes("xamaCheckout") && (
                  <Route path="/xamacheckout" element={<XamaCheckout />} />
                )}

                {screens.includes("xptoPaymentVerify") && (
                  <Route path="/xptoPayments" element={<XptoPaymentVerify />} />
                )}

                {screens.includes("xptoAntecipacaoViz") && (
                  <Route
                    path="/antecipacaoViz"
                    element={<XptoAntecipacaoViz />}
                  />
                )}

                {screens.includes("trades") && (
                  <Route path="/trades" element={<Trades />} />
                )}
                {screens.includes("negs") && (
                  <Route
                    path="/negs"
                    element={
                      <UploadPage
                        key="negs"
                        title="Negociações"
                        subtitle="Upload de arquivos de negociações"
                        docType="negs_file"
                        extensionFile=".txt"
                        uploadTitle="Upload Negociação"
                      />
                    }
                  />
                )}

                {screens.includes("xptoPersonStatus") && (
                  <Route path="/personStatus" element={<XptoPersonStatus />} />
                )}

                {screens.includes("xptoCheckout") && (
                  <Route path="/xptocheckout" element={<XptoCheckout />} />
                )}

                {/* <Route path="/faq" element={<Faq />} /> */}
              </Routes>
            </main>
          </div>
        </SidebarContextProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
