import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
} from "@mui/material";

const ConfirmationButton = ({ 
  title, 
  message, 
  open, 
  setOpen, 
  onConfirm, 
  confirmDisabled, 
  confirmLabel = "Sim", 
  cancelLabel = "Não" 
}) => {
  const theme = useTheme();

  const listSX = {
    backgroundColor: theme.palette.primary.main,
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog" sx={listSX}>
        {title}
      </DialogTitle>
      <DialogContent sx={listSX}>
        <p>{message}</p>
      </DialogContent>
      <DialogActions sx={listSX}>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.danger.main,
          }}
        >
          {cancelLabel} 
        </Button>
        <Button
          variant="contained"
          disabled={confirmDisabled}
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          sx={{
            color: theme.palette.neutral.light,
            backgroundColor: theme.palette.primary.dark,
          }}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationButton;
