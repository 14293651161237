import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";

const GenerateForm = ({ fields, onSubmit }) => {
  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );

  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData); 
  };

  return (
    <Box
      component="form"
      onSubmit={handleFormSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 400,
        margin: "auto",
      }}
    >
      {fields.map((field) => (
        <TextField
          key={field.name}                      
          label={field.label}                  
          type={field.type === "number" ? "number" : "text"} 
          value={formData[field.name]}        
          onChange={(e) => handleChange(field.name, e.target.value)} 
          variant="outlined"
        />
      ))}
      <Button type="submit" variant="contained" color="primary">
        Enviar
      </Button>
    </Box>
  );
};

export default GenerateForm;
