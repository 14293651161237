import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPost } from "../../global/utilFunctions/apiRequests";
import Table from "../../global/tableComponents/Table";
import ConfirmationButton from "../../global/formsComponents/ConfirmationButton";
import GenerateForm from "../../global/formsComponents/GenerateForm";
import Header from "../../global/layoutComponents/Header";

const Trades = () => {
  const theme = useTheme();
  const { logout } = useAuth0();
  const accessToken = useSelector((state) => state.auth0.accessToken);
  const [tableData, setTableData] = useState(null);
  const [error, setError] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const formFields = [
    { label: "Tamanho do Passo", name: "tamanhoDoPasso", type: "number" },
    { label: "Valor(BRL)", name: "brlParaAdicionar", type: "number" },
  ];

  const tableColumns = [
    { field: "id", headerName: "ID", flex: 1, hide: true },
    { field: "nome_completo", headerName: "Nome Completo", flex: 1 },
    { field: "numero_documento", headerName: "CPF", flex: 1 },
    { field: "telefone", headerName: "Telefone" },
  ];

  const mockTableData = {
    cadastro: [
      { id: 1, nome_completo: "João Silva", numero_documento: "123.456.789-00", telefone: "(11) 91234-5678" },
      { id: 2, nome_completo: "Maria Oliveira", numero_documento: "987.654.321-00", telefone: "(21) 99876-5432" },
    ],
  };

  useEffect(() => { //API chamada logo quando a página é carregada
    if (accessToken) {
      apiPost("/", {}, accessToken, logout)
        .then((value) => {
          setTableData(value);
          setError(null);
        })
        .catch((err) => {
          console.error(err);
          setError("Erro ao carregar as informações.");
        });
    }
  }, [accessToken, logout]);


  const handleFormSubmit = (formData) => { //API para enviar os dados do formulário
    setError(null);
    const tamanhoDoPasso = parseFloat(formData.tamanhoDoPasso)
    const brlParaAdicionar = parseFloat(formData.brlParaAdicionar)
    const transformedData = {
      "fundName": "planalto_capital_fia",
      "tamanhoDoPasso":tamanhoDoPasso,
      "brlParaAdicionar": brlParaAdicionar
    };
    console.log(transformedData)
    if (tamanhoDoPasso < 0 || tamanhoDoPasso > 1) {
      setError("O valor de 'Tamanho do Passo' deve estar entre 0 e 1.");
      return;
    }
    apiPost("/FIATrade", transformedData, accessToken, logout)
      .then((response) => {
        console.log("Resposta da API")
        console.log(response)
        console.log(JSON.stringify(response))
      })
      .catch((err) => {
        console.error(err);
        setError("Erro ao carregar as informações. Verifique os dados e tente novamente.");
      });
  };

  const startAPICall = () => {//API a ser chamada depois da tabela ser exibida
    const interval = setInterval(() => {
      apiPost("/", {}, accessToken, logout)
        .then((response) => {
          if (response?.data) {
            setTableData(response.data);
            clearInterval(interval);
          }
        })
        .catch((err) => {
          console.error("Erro ao realizar o trade:", err);
          setError("Erro ao realizar o trade. Tente novamente.");
        });
    }, 5000);
    setIntervalId(interval);
  };

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,minmax(100px, 1fr))"
        gap="20px"
        mt="60px"
        marginBottom="40px"
      >
        <Box gridColumn="span 6" display="flex" alignItems="center">
          <Header title="TRADES" subtitle="Página para realizar trades" />
        </Box>
      </Box>

      <Box mt="20px">
        <GenerateForm fields={formFields} onSubmit={handleFormSubmit} />
      </Box>

      {tableData && (
        <Box mt="20px" display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="error"
            sx={{ fontSize: "16px", padding: "12px 24px", minWidth: "200px" }}
            onClick={() => setConfirmOpen(true)}
          >
            Realizar Trade
          </Button>
        </Box>
      )}

      {error && (
        <Box mt="10px" color={theme.palette.error.main}>
          <Typography variant="body2">{error}</Typography>
        </Box>
      )}

      {mockTableData.cadastro.length > 0 && (//Trocar o mock data oara tableData quando estiver funcionando
        <Box mt="20px">
          <Typography variant="h6">Tabela trade</Typography>
          <Table
            checkBoxSelection={false}
            jsonData={mockTableData.cadastro}//Trocar o mock data oara tableData quando estiver funcionando
            columns={tableColumns}
            fileName="SolicitacoesAntecipacoes"
          />
        </Box>
      )}

      <ConfirmationButton
        message="Deseja Realizar a Trade?"
        confirmText="SIM"
        cancelText="NÃO"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={startAPICall}
      />
    </Box>
  );
};

export default Trades;
